.onboarding {
    position: absolute;
    top: -18px;
    bottom: 0;
    z-index: 99999999999999999;

    width: 100%;
    height: 100%;

    background-color: white;

    padding: 75px 30px 0 30px;

    text-align: center;

    h3 {
        margin-bottom: 8px;
    }

    p {
        font-size: 14px;
        color: #444;
    }

    .dots {
        position: absolute;

        bottom: 25px;
        left: 50%;
        transform: translateX(-50%);
        
        width: calc(100% - 200px);

        display: flex;
        justify-content: space-between;

        .line {
            position: absolute;
        }

        .dot {
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 100%;

            background-color: #aaa;

            outline: 8px solid white;
            
            z-index: 1;

            &.is-active {
                background-color: #111;
            }
        }
    }

    .semester {
        height: calc(750px*0.625);
        max-height: 315px;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
        }

        margin-top: 18px;

        .semester-area {
            width: 100%;

            margin-bottom: 9px;

            border-radius: 5px;

            text-align: left;
            
            background-color: #f3f3f3;

            user-select: none;

            &.is-active {
                span.title {
                    background-image: url("data:image/svg+xml;utf8,<svg transform='rotate(180)' fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                    background-repeat: no-repeat;
                    background-position-x: calc(100% - 16px);
                    background-position-y: 50%;
                }

                .module-list {
                    display: block;
                }
            }
            &.is-inactive {
                span.title {
                    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                    background-repeat: no-repeat;
                    background-position-x: calc(100% - 16px);
                    background-position-y: 50%;
                }

                .module-list {
                    display: none;
                }
            }

            span.title {
                display: block;
                width: 100%;
                padding: 9px 16px;

                font-size: 14px;
            }

            .module-list {
                padding: 0 9px 9px 16px;

                .module {
                    display: inline-block;

                    margin-right: 5px;
                    margin-bottom: 5px;

                    font-size: 12px;
                    padding: 2.5px 5px;

                    border-radius: 3.5px;

                    cursor: pointer;

                    i {
                        margin-left: 5px;
                    }

                    &.is-active {
                        background: #fff;
                        outline: 1px solid #ccc;
                        color: #000;

                        .add-icon {
                            display: none;
                        }
                    }
                    &.is-inactive {
                        background: #e5e5e5;
                        outline: 1px solid #ccc;
                        color: #333;

                        .remove-icon {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .btns {
        position: absolute;

        width: calc(100% - 60px);

        bottom: 75px;
        left: 50%;
        transform: translateX(-50%);

        .btn-text {
            color: #555;
        }
    }

    .loading-spinner.sneaky {
        all: unset;
        position: absolute;
        z-index: 9999999;
        
        bottom: 0;
        left: 0;

        height: 100%;
        width: 100%;

        border-radius: 20px 20px 0 0;

        &:before {
            content: '';
            box-sizing: border-box;
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            margin-top: -15px;
            margin-left: -15px;
            border-radius: 50%;
            border: 1px solid #ccc;
            border-top-color: rgb(221, 0, 29);
            animation: spinner .6s linear infinite;
            @keyframes spinner {
                to {transform: rotate(360deg);}
            }
        }
    }
}

